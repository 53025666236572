<template>
	<v-container>
		<v-layout row>
			<Progress :error="error" :loading="loading" type="overlay"></Progress>
			<v-flex style="flex:48px" v-if="person && person.photo">
				<v-avatar>
					<image-dialog
						:image_original="getPhotoPath('person', person.id) + person.photo + '_original.jpg'"
						:title="title"
						:image="getPhotoPath('person', person.id) + person.photo + '_48.jpg'"
						:image2x="getPhotoPath('person', person.id) + person.photo + '_96.jpg'"
					></image-dialog>
				</v-avatar>
			</v-flex>
			<v-flex>
				<h1 class="text-center">{{ person ? title : "" }}</h1>
			</v-flex>
		</v-layout>

		<template v-if="director && director.length > 0">
			<v-layout row>
				<v-flex xs12>
					<h2 class="text-center">В качестве режиссёра</h2>
				</v-flex>
			</v-layout>
			<v-layout row>
				<v-flex xs12>
					<channel-list :items="director"></channel-list>
				</v-flex>
			</v-layout>
		</template>

		<template v-if="writer && writer.length > 0">
			<v-layout row>
				<v-flex xs12>
					<h2 class="text-center">В качестве сценариста</h2>
				</v-flex>
			</v-layout>
			<v-layout row>
				<v-flex xs12>
					<channel-list :items="writer"></channel-list>
				</v-flex>
			</v-layout>
		</template>

		<template v-if="actor && actor.length > 0">
			<v-layout row>
				<v-flex xs12>
					<h2 class="text-center">В качестве актёра</h2>
				</v-flex>
			</v-layout>
			<v-layout row>
				<v-flex xs12>
					<channel-list :items="actor"></channel-list>
				</v-flex>
			</v-layout>
		</template>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ImageDialog from "../components/helper/image-dialog.vue";
import { Helper } from "../mixins/Helper";

function fetchData(id) {
	return window.fetchData("/api/person/" + id, {});
}
export default {
	mixins: [Helper],
	components: { ChannelList, ImageDialog },
	data() {
		return {
			loading: true,
			director: null,
			writer: null,
			actor: null,
			person: null,
			error: null
		};
	},
	created() {
		document.title = '';
		if (process.env.NODE_ENV != 'production') {
			this.$nextTick(() => {
				if (this.loading == true) this.fetchData();
			});
		}
	},
	computed: {
		title: function() {
			return this.getPersonName(this.person);
		}
	},
	methods: {
		setData(data) {
			let t = this;

			t.loading = false;
			if (!data.error) {
				t.person = data.person;
				t.writer = data.writer;
				t.director = data.director;
				t.actor = data.actor;
				document.title = t.title;
			} else {
				t.error = data;
			}
		},
		async fetchData() {
			let t = this;
			t.error = t.person = null;
			t.loading = true;

			let data = await fetchData(t.$route.params.id);
			t.setData(data);
		}
	},
	async beforeRouteEnter(to, from, next) {
		let data = await fetchData(to.params.id);

		next(vm => {
			vm.setData(data);
		});
	},
};
</script>
<style scoped>
</style>